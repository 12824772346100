/* You can add global styles to this file, and also import other style files */
@font-face {
    font-family: lato-regular;
    src: url(assets/fonts/lato-regular.ttf);
    font-weight: 400;
  }
  
  @font-face {
    font-family: lato-extrabold;
    src: url(assets/fonts/Lato-Black.ttf);
    font-weight: 800;
  }
  @font-face {
    font-family: lato-bold;
    src: url(assets/fonts/Lato-Bold.ttf);
    font-weight: 700;
  }
  
  
  @font-face {
    font-family: lato-regular;
    src: url(assets/fonts/Lato-Regular.ttf);
    font-weight: 400;
  }
  @font-face {
    font-family: lato-Light;
    src: url(assets/fonts/Lato-Light.ttf);
    font-weight: 300;
  }
  @font-face {
    font-family: lato-thin;
    src: url(assets/fonts/Lato-LightItalic.ttf);
    font-weight: 200;
  }

  body{
      margin:0px
  }
html, body { height: 100%; }

.mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: rgba($color: #A4ACB2, $alpha: 0.5);
}

.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid
.mat-form-field-outline-thick {
    color: #f44336;
}
.primary-btn{
    border: 1px solid #17709c;
    background: #17709c;
    color: #fff;
    outline: none;
    font-family: lato-bold;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    padding: 10px 10px;
    box-sizing: border-box;
    border-radius: 2px;
    letter-spacing: .4px;
    display: flex;
    align-items: center;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: unset;
    min-width: 130px;
}
.mat-form-field-appearance-legacy .mat-form-field-wrapper {
    padding-bottom: 1.25em;
}


.mat-form-field-appearance-legacy.mat-focused .mat-form-field-label{
    color: rgba(0,0,0,.54);
    caret-color: #17709c;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
    color: rgba(0,0,0,.54);
    font-size: 15px!important;
    
}
.mat-input-element {
    caret-color: #17709c;
    color:#00314F!important;
    font-family: lato-regular!important;
    font-size: 12px!important;
}

.mat-error{
    color: #ed102f;
    font-size: 12px;
    margin-top: 5px;
    font-family: lato-regular!important;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
    color: rgba(0,0,0,.54)!important;
}

.dispatch-material-form{
    ::ng-deep .mat-form-field-type-mat-select .mat-form-field-infix {
        padding: 0px 0 5px 0 !important;
    }
    ::ng-deep .mat-form-field-appearance-outline .mat-form-field-infix {
        padding: 3px 0 12px 0 !important;
    }
    .btn-secondary{
        padding:7px 10px;
    }
    ::ng-deep .mat-form-field-appearance-legacy .mat-form-field-underline {
        bottom: 0;
    }
    ::ng-deep .mat-form-field-appearance-legacy .mat-form-field-wrapper {
        padding-bottom: 0px;
    }
    ::ng-deep .mat-form-field-appearance-legacy .mat-form-field-label {
        // color: rgba(0, 0, 0, 0.54);
        font-size: 14px;
        opacity:0.8;
    }
    ::ng-deep .mat-form-field-infix {
        padding: 0px 0 5px 0 !important;
        border-top: none;
    }
    ::ng-deep .mat-form-field.mat-focused .mat-form-field-ripple {
        background-color:  transparent;
    }
    ::ng-deep .mat-form-field-type-mat-select .mat-form-field-infix {
        padding: 0px 0 5px 0 !important;
    }
    ::ng-deep .mat-input-underline {
        background-color: white;
    }
    ::ng-deep .mat-form-field-label-wrapper {
        top: -1.3em;
        padding-top: 0.84375em;
    }
    ::ng-deep .mat-select-arrow {
        margin: 2px 4px !important;
    }
}

button[disabled] {
    cursor: no-drop!important;
    opacity: .4!important;
}
button.primary-btn{

&:disabled {
    border: 1px solid rgba(135,165,193,.3);
    background: rgba(135,165,193,.3);
    color: #7d7d7d;
}
}

.default-page-padding{
    padding:0px 15px;
}
.no-margin{
    margin:0;
}
.ff_flex{
    display: flex;
}
.mat-checkbox-frame {
    background-color: transparent;
    transition: border-color 90ms cubic-bezier(0, 0, 0.2, 0.1);
    border-width: 1px!important;
    border-style: solid;
    border-color: #A4ACB2!important;
}

.mat-calendar-arrow {
    border-top-color: rgba(0, 0, 0, 0.54);
  }
  .mat-datepicker-content .mat-calendar-next-button,
  .mat-datepicker-content .mat-calendar-previous-button,
  .mat-datepicker-toggle {
    color: rgba(0, 0, 0, 0.54);
  }
  .mat-calendar-table-header {
    color: rgba(0, 0, 0, 0.38);
  }
  .mat-calendar-table-header-divider::after {
    background: rgba(0, 0, 0, 0.12);
  }
  .mat-calendar-body-label {
    color: rgba(0, 0, 0, 0.54);
  }
  .mat-calendar-body-cell-content {
    color: rgba(0, 0, 0, 0.87);
    border-color: transparent;
  }
  .mat-calendar-body-disabled
    > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
    color: rgba(0, 0, 0, 0.38);
  }
  .cdk-keyboard-focused
    .mat-calendar-body-active
    > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
  .cdk-program-focused
    .mat-calendar-body-active
    > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
    > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
    background-color: rgba(0, 0, 0, 0.04);
  }
  .mat-calendar-body-today:not(.mat-calendar-body-selected) {
    border-color: rgba(0, 0, 0, 0.38);
  }
  .mat-calendar-body-disabled
    > .mat-calendar-body-today:not(.mat-calendar-body-selected) {
    border-color: rgba(0, 0, 0, 0.18);
  }
  .mat-calendar-body-selected {
    background-color: #17709C;
    color: #fff;
  }
  .mat-calendar-body-cell-content {
    position: absolute;
    top: 5%;
    left: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 90%;
    height: 90%;
    line-height: 1;
    border-width: 0px!important;
    border-style: none!important;
    border-radius: 25px!important;
}
  .mat-calendar-body-disabled > .mat-calendar-body-selected {
    background-color: rgba(103, 58, 183, 0.4);
  }
  .mat-calendar-body-today.mat-calendar-body-selected {
    box-shadow: inset 0 0 0 1px #fff;
  }
  .mat-datepicker-content {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
  }
  .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
    background-color: #17709C;
    color: rgba(0, 0, 0, 0.87);
  }
  .mat-datepicker-content.mat-accent
    .mat-calendar-body-disabled
    > .mat-calendar-body-selected {
    background-color: rgba(255, 215, 64, 0.4);
  }
  .mat-datepicker-content.mat-accent
    .mat-calendar-body-today.mat-calendar-body-selected {
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
  }
  .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
    background-color: #f44336;
    color: #fff;
  }
  .mat-datepicker-content.mat-warn
    .mat-calendar-body-disabled
    > .mat-calendar-body-selected {
    background-color: rgba(244, 67, 54, 0.4);
  }
  .mat-datepicker-content.mat-warn
    .mat-calendar-body-today.mat-calendar-body-selected {
    box-shadow: inset 0 0 0 1px #fff;
  }
  .mat-datepicker-toggle-active {
    color: #17709C;
  }
  .mat-datepicker-toggle-active.mat-accent {
    color: #17709C;
  }
  .mat-datepicker-toggle-active.mat-warn {
    color: #f44336;
  }

  .mat-datepicker-toggle.mat-datepicker-toggle-active{
    .grey{
      display: none;
    }
    .blue{
      display:block;
      position: relative;
      top: 5px;
      left: 0px;
    }

}
.mat-datepicker-toggle{
  .blue{
    display: block;
  }
  .blue{
    display:none;
  }

}
.custom-datepicker{
  button{
    position: relative;
    top: -3px;
  }

}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: #00314f!important;
}
.mat-checkbox-input {
    bottom: 0;
    left: 50%;
    height:16px;
    width:16px;
    margin: 0px;
}

.date-time-class .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 0px!important;
    border-top:hidden;
}
.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-start, .mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-end, .mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-gap {
    border-width: 1px!important;
}

.mat-form-field-appearance-outline .mat-form-field-outline-thick {
    color: rgba(0,0,0,.12)!important;
}
.mat-form-field-prefix .mat-icon-button, .mat-form-field-suffix .mat-icon-button {
    height: 39px!important;
    width: 1.5em;
}
.date-time-class{
    margin: 10px 0;
    .mat-form-field-wrapper {
        padding-bottom:0px!important;
        margin: 0!important;
    }
    .mat-input-element {
        caret-color: #17709c;
        color: #00314F !important;
        font-family: lato-regular !important;
        font-size: 12px !important;
    }
    
}

.time-picker{
    margin-bottom: 10px;
    .mat-form-field-appearance-outline .mat-form-field-infix {
        padding: 10px 0px !important;
        border-top: hidden;
    }
    .mat-form-field-wrapper {
        padding-bottom: 0!important;
    }
}